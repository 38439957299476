import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const Content = ({ children }) => {
  return (
    <S.Grid
      container
      item
      my={2}
      maxWidth={1100}
    >
      {children}
    </S.Grid>
  )
}

Content.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Content
