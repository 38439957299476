import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import Content from '../Content/Content';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import * as S from './styles';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      sm: 750,
    },
  },
});

const Layout = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>  
      <S.Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Header />
        <Content children={children} />
        <Footer />
      </S.Grid>
    </ThemeProvider>
  </MuiThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
