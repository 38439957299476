import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Wrapper, Title } from './styles';

const Header = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  return (
    <Wrapper
      container
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Title>{site.siteMetadata.title}</Title>
    </Wrapper>
  )
}

export default Header;
