import React from 'react';
import { Wrapper } from './styles';

const Footer = () => {
  return (
    <Wrapper>
      © {new Date().getFullYear()}
    </Wrapper>
  )
}

export default Footer;
