import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const Wrapper = styled(Grid)`
  background-color: #022a32;
  height: 200px;
`;

export const Title = styled.h1`
  margin: 0;
  color: #fbcd1f;
`;